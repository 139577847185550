 @media(max-width: 1025px){
  .print-bill{
     width: 50% !important;
  }
}
@media(max-width: 769px){
  .print-bill{
     width: 70% !important;
  }
}
@media(max-width: 417px){
  .print-bill{
     width: 95% !important;
  }
} 
img{
  margin-top: 10px;
}
@page {
  size: A4 !important;
}
@media print {
  .print-bill{
    width: 98% !important;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 35px;
    margin-bottom: 15px;
  } 
  #header,#footer, .toolbar{
   display: none;
 }
 body{
  background: white !important;
 }
 

}
